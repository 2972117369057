import React from "react";
import { Box } from "@mui/material";

export default function About() {
  return (
    <Box
      sx={{
        width: "80%",
        display: "flex",
        justifyContent: "center",
        color: "#e0e1dd",
      }}
    >
      <Box>
        I'm a software engineer co-founding{" "}
        <a
          href="https://www.aethos.network"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          {" "}
          Aethos
        </a>
        , an early-stage startup that pushes self-sovereignty forward in digital
        finance.
        <br />
        <br />
        In the past, I've worked on a variety of projects including software
        testing optimization at{" "}
        <a
          href="https://www.jpmorgan.com"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          JPMorgan
        </a>
        , on & offramps at{" "}
        <a
          href="https://ponto.org/"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          Ponto
        </a>
        , and partnerships at{" "}
        <a
          href="https://www.celo.org"
          style={{ textDecoration: "none", color: "#7C968D" }}
        >
          Celo
        </a>
        .
        <br />
        <br />
        I'm interested in self-sovereignty, efficient systems, music, connecting
        people, and good lighting.
      </Box>
    </Box>
  );
}
