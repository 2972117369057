import Grid from "@mui/material/Grid";
import React, { useEffect, useRef, useState } from "react";
import { Button, useMediaQuery } from "@mui/material";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";

export default function Sidebar({ onViewChange }) {
  const isPhone = useMediaQuery("(max-width:600px)");
  const [activeTab, setActiveTab] = useState("work");

  const [firstPlay, setFirstPlay] = useState(true); // New state variable
  const audioRef = useRef(new Audio("/music.m4a"));
  const [isPaused, setIsPaused] = useState(false); // Ne
  const handleClick = (view) => {
    setActiveTab(view);
    onViewChange(view === "life");
  };

  const handleToggleMusic = () => {
    setIsPaused(!isPaused);
    if (isPaused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const tabStyle = (tab) => ({
    fontWeight: activeTab === tab ? "bold" : "bold",
    cursor: "pointer",
    borderBottom: activeTab === tab ? "2px solid #DAF3FF" : "none", // bottom border for active tab
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        audioRef.current.pause();
        setIsPaused(true); // Update the state to reflect the change
      } else if (activeTab === "life" && !isPaused) {
        audioRef.current.play();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    if (activeTab === "life" && !isPaused) {
      audioRef.current.volume = 0.3;
      if (firstPlay) {
        audioRef.current.currentTime = 0;
        setFirstPlay(false);
      }
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error("Playback failed.", error);
        });
      }
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [activeTab, firstPlay, isPaused]); // Include isPaused in the dependency array

  return (
    <Grid container sx={{ width: "80%" }}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          fontSize: 22,
          whiteSpace: "nowrap",
          color: "#e0e1dd",
        }}
      >
        J E S S E&nbsp; S A W A
      </Grid>

      <Grid
        item
        xs={isPhone ? 2 : 1}
        md={12}
        sx={{ pt: { xs: 2, md: 3 }, fontSize: 18 }}
      >
        <span style={tabStyle("work")} onClick={() => handleClick("work")}>
          Work
        </span>
      </Grid>
      <Grid
        item
        xs={isPhone ? 10 : 11}
        md={12}
        sx={{ pt: { xs: 2, md: 1 }, pl: { xs: 3, md: 0 }, fontSize: 18 }}
      >
        <span style={tabStyle("life")} onClick={() => handleClick("life")}>
          Life
        </span>
        {activeTab === "life" && (
          <Button
            onClick={handleToggleMusic}
            size="small"
            sx={{ marginLeft: 1 }}
          >
            {isPaused ? (
              <PlayArrowOutlinedIcon fontSize="small" />
            ) : (
              <PauseCircleOutlineIcon fontSize="small" />
            )}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
